



























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ArrowLeftIcon, ThumbsUpIcon } from "vue-feather-icons";
import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";

@Component({
  components: {
    ArrowLeftIcon,
    ThumbsUpIcon,
    Loader,
    NoData,
  },
})
export default class NewOperator extends Vue {
  /* eslint-disable @typescript-eslint/camelcase */
  isEditModeActive = false;
  basicDetailData = "";
  showNoData = false;
  noDataType = "Error";
  isDataLoading = false;

  $refs!: {
    name: HTMLInputElement;
  };
  mounted() {
    const id = this.$router.currentRoute.params.id || null;
    if (id !== null) {
      this.isEditModeActive = true;
      this.isDataLoading = true;
      this.fetchOperatorWithId(id);
    } else {
      this.fetchAssetsList();

      this.basicDetailData = "true";
      setTimeout(() => {
        this.$refs.name.focus();
      }, 10);
    }
  }

  reloadData() {
    this.showNoData = false;
    this.isDataLoading = true;
    const id = this.$router.currentRoute.params.id || null;
    if (id === null) {
      return;
    }
    this.fetchOperatorWithId(id);
  }

  async fetchOperatorWithId(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        await this.fetchAssetsList();

        const data = await response.json();
        this.basicDetailData = data;
        // Auto filling value for easy editing
        this.id = data.id;
        this.name = data.name;
        this.pin = data.pin;
        this.asset_id = data.asset_id;
        this.mobile_no = data.mobile_no;
        this.aadhaar_no = data.aadhaar_no;
        this.bank_account_no = data.bank_account_no;
        this.bank_ifsc_code = data.bank_ifsc_code;
        this.driving_license = data.driving_license;
        this.date_of_joining = data.date_of_joining;
        this.date_of_leaving = data.date_of_leaving;
        this.net_inhand_salary_fixed = data.net_inhand_salary_fixed;
        this.pf_account_no = data.pf_account_no;

        setTimeout(() => {
          this.$refs.name.focus();
        }, 10);
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      console.log(e);
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isDataLoading = false;
    }
  }

  async fetchAssetsList() {
    try {
      this.isDataLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        this.assetsList = await response.json();
        if (this.asset_id === "") {
          this.assetsList.unshift({ id: "", brand: "No Asset" });
          // this.asset_id = this.assetsList[0].id;
        }
        setTimeout(() => {
          this.$refs.name.focus();
        }, 10);
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isDataLoading = false;
    }
  }

  // variables
  assetsList: any = [];
  asset_id = "";
  asset_idError = "";

  id = "";

  name = "";
  nameError = "";

  pin = "";
  pinError = "";

  mobile_no = "";
  mobile_noError = "";

  aadhaar_no = "";
  aadhaar_noError = "";

  bank_account_no = "";
  bank_account_noError = "";

  bank_ifsc_code = "";
  bank_ifsc_codeError = "";

  driving_license = "";
  driving_licenseError = "";

  date_of_joining = "";
  date_of_joiningError = "";

  date_of_leaving = "";
  date_of_leavingError = "";

  net_inhand_salary_fixed = "";
  net_inhand_salary_fixedError = "";

  pf_account_no = "";
  pf_account_noError = "";

  @Watch("name") onwatchtitle() {
    this.nameError = "";
  }
  @Watch("pin") onwatchdescription() {
    this.pinError = "";
  }
  @Watch("asset_id") onwatchasset_id() {
    this.asset_idError = "";
  }

  isLoading = false;
  async onSubmitMaintenanceData() {
    try {
      this.isLoading = true;

      let data;

      if (this.isEditModeActive) {
        data = {
          name: this.name,
          pin: this.pin.toString(),
          asset_id: this.asset_id,
          mobile_no: this.mobile_no,
          aadhaar_no: this.aadhaar_no,
          bank_account_no: this.bank_account_no,
          bank_ifsc_code: this.bank_ifsc_code,
          driving_license: this.driving_license,
          date_of_joining: this.date_of_joining,
          date_of_leaving: this.date_of_leaving,
          net_inhand_salary_fixed: this.net_inhand_salary_fixed,
          pf_account_no: this.pf_account_no,
        };
      } else {
        data = {
          name: this.name,
          pin: this.pin,
          asset_id: this.asset_id,
          mobile_no: this.mobile_no,
          aadhaar_no: this.aadhaar_no,
          bank_account_no: this.bank_account_no,
          bank_ifsc_code: this.bank_ifsc_code,
          driving_license: this.driving_license,
          date_of_joining: this.date_of_joining,
          date_of_leaving: this.date_of_leaving,
          net_inhand_salary_fixed: this.net_inhand_salary_fixed,
          pf_account_no: this.pf_account_no,
        };
      }

      let response;
      if (this.isEditModeActive) {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator/${this.id}`,
          {
            method: "PUT",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );
      } else {
        response = await fetch(
          `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/operator`,
          {
            method: "POST",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          }
        );
      }

      if (response.status == 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Success!",
          text: this.isEditModeActive
            ? "Operator edited successfully"
            : "Operator added successfully",
          type: "Success",
        });
        this.$router.replace({ path: "/asset-tracker/operator" });
      } else if (response.status == 400) {
        const error = await response.json();
        switch (error[0].path[0]) {
          case "name":
            this.nameError = error[0].message;
            break;
          case "pin":
            this.pinError = error[0].message;
            break;
          case "asset":
            this.asset_idError = error[0].message;
            break;
          default:
            break;
        }
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }
}
